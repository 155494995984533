import DocViewer, { DocViewerRenderers, IDocument } from '@chalkbooks/react-doc-viewer';
import { confirmAlert } from 'react-confirm-alert';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { showNotification } from '../../service/notificationStore';
import {
	DynamicUploadDocument,
	FileDetailForLogin,
	RigidUploadDocument,
	ValidationRules,
} from '../../store/slice';
import { FileDetail, FileModel } from '../../store/slice/download';
import { FileMimeType, fileTypesForUpload } from '../../store/slice/download/download.initialState';
import {
	DocumentNoteHistory,
	FilevalidationRulesType,
	FilevalidationType,
	UploadFileDetails,
} from '../../store/slice/upload';
import ConfirmDailog from '../ConfirmDailog/ConfirmDailog';
import ConfirmdialogResponsive from '../ConfirmDailog/ConfirmdialogResponsive';
import FilePreviewComponent from './FilePreviewComponent';
import InfoYellowIcon from '../../assets/images/info-icon-dark-yellow-ract.svg';
import OKIcon from '../../assets/images/tick-green.svg';
import RedCrossIcon from '../../assets/images/cross-icon-red-ract.svg';

const maxFileUploadInMB = 20;
export const createBlob = (base64String: string, contentType: string) => {
	const byteArray = Uint8Array.from(
		atob(base64String)
			.split('')
			.map(char => char.charCodeAt(0)),
	);
	return new Blob([byteArray], { type: contentType });
};

export const downloadFile = (fileInfo: FileModel) => {
	const blob = createBlob(fileInfo.fileDetail.fileBase64 ?? '', fileInfo.fileDetail.fileType);
	const downloadLink = document.createElement('a');
	const fileName = `${fileInfo.fileDetail.fileName}.${fileInfo.fileDetail.fileType}`;
	downloadLink.href = window.URL.createObjectURL(blob);
	downloadLink.download = fileName;
	downloadLink.click();
	showNotification({
		type: 'success',
		message: 'Il documento è stato scaricato correttamente.',
		title: '',
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function base64ToBlob(base64: any, contentType: string) {
	const byteCharacters = atob(base64);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		const slice = byteCharacters.slice(offset, offset + 512);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: contentType });
}

export const downloadfile = (allPreviewableDocs: IDocument[], fileName: string) => {
	allPreviewableDocs.forEach(e => {
		if (e.fileName === fileName) {
			// Example base64 data (replace this with your actual base64 data)
			const base64Data = e.fileData;
			const contentType = e.fileType ?? ''; // Adjust based on your file type
			// Convert base64 to Blob
			const blob = base64ToBlob(base64Data, contentType);
			// Create a link element
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = e?.fileName ?? ''; // Specify the filename and extension
			// Append link to the body (needed for Firefox)
			document.body.appendChild(link);
			// Programmatically click the link to trigger the download
			link.click();
			// Remove the link from the document
			document.body.removeChild(link);
		}
	});
};
export const fileViewer = (d: FileDetail[]) => {
	const allPreviewableDocs: IDocument[] = d
		.filter(fs => fs.fileBase64)
		.map(m => {
			const data: IDocument = {
				uri: window.URL.createObjectURL(
					base64ToBlob(m.fileBase64, getMimeType(m.fileType)),
				),
				fileData: m.fileBase64 || '',
				fileType: m.fileType || '',
				fileName: m.fileName + '.' + m.fileType,
			};
			return data;
		});
	setTimeout(() => {
		const element = document.getElementById('pdf-download') as HTMLAnchorElement;
		if (element) {
			const url = element.getAttribute('href');
			const filename = element.getAttribute('download') ?? '';
			element.setAttribute('data', `${url}`);
			// element.setAttribute('href', '#');
			element.removeAttribute('href');
			element.addEventListener(
				'click',
				function () {
					downloadfile(allPreviewableDocs, filename);
				},
				false,
			);
		}
	}, 1000);

	return (
		<DocViewer
			config={{
				header: {
					disableHeader: false,
					disableFileName: true,
				},
				pdfVerticalScrollByDefault: true,
				pdfZoom: { defaultZoom: 1, zoomJump: 1 },
				noRenderer: {},
			}}
			documents={allPreviewableDocs}
			pluginRenderers={DocViewerRenderers}
		/>
	);
};

export const previewOfDocForLogin = (d: FileDetailForLogin[], title: string) => {
	{
		d.length > 0 &&
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<ConfirmDailog onClose={onClose} title={title}>
							{/* {fileViewer(d)} */}
							<FilePreviewComponent d={d} />
						</ConfirmDailog>
					);
				},
			});
	}
};
export const previewOfDoc = (d: FileDetail[], title: string) => {
	{
		d.length > 0 &&
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<ConfirmDailog onClose={onClose} title={title}>
							{/* {fileViewer(d)} */}
							<FilePreviewComponent d={d} />
						</ConfirmDailog>
					);
				},
			});
	}
};
export const responsivePreviewOfDoc = (d: FileDetail[], title: string) => {
	{
		d.length > 0 &&
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<ConfirmdialogResponsive onClose={onClose} title={title}>
							{/* {fileViewer(d)} */}
							<FilePreviewComponent d={d} />
						</ConfirmdialogResponsive>
					);
				},
			});
	}
};
export const convertToNumber = (value: string): number | undefined => {
	return value === '' || isNaN(Number(value)) ? undefined : Number(value);
};

export const fileValidationRules = (
	file: UploadFileDetails,
	data: RigidUploadDocument[] | DynamicUploadDocument[],
): FilevalidationRulesType => {
	const orientation = data.find(x => x.documentList.transcode === file.documentIdentifyingCode)
		?.documentList.validationRules.orientation;
	return {
		orientation:
			orientation?.id && Number(orientation?.id) < 1 ? undefined : orientation?.value,
		resolution:
			data.find(x => x.documentList.transcode === file.documentIdentifyingCode)?.documentList
				.validationRules.resolution ?? undefined,
		pageNumbers: {
			min: convertToNumber(
				data.find(x => x.documentList.transcode === file.documentIdentifyingCode)
					?.documentList.validationRules.pageNumber.minPage ?? '',
			),
			max: convertToNumber(
				data.find(x => x.documentList.transcode === file.documentIdentifyingCode)
					?.documentList.validationRules.pageNumber.maxPage ?? '',
			),
		},
	};
};

export const checkValidationsForFileUpload = (
	fileData: FilevalidationType,
	validationRules: FilevalidationRulesType,
): boolean => {
	let validationMessage = '';
	if (
		validationRules.orientation &&
		fileData.orientation &&
		['.PDF', '.JPEG', '.PNG', '.JPG'].filter(fileType =>
			fileData.fileName.toUpperCase().endsWith(fileType),
		).length > 0
	) {
		if (
			!(
				Object.values(fileData.orientation).filter(
					value => validationRules.orientation?.toLowerCase() === value.toLowerCase(),
				).length === Object.keys(fileData.orientation).length
			)
		)
			validationMessage = `Puoi caricare solamente file con orientamento orizzontale ${validationRules.orientation}.Si prega di verificare e riprovare.`;
	}

	if (
		fileData.pageCount &&
		['.PDF', '.DOCX', '.DOC'].filter(fileType =>
			fileData.fileName.toUpperCase().endsWith(fileType),
		).length > 0 &&
		!validationMessage
	) {
		if (
			(validationRules.pageNumbers.min &&
				fileData.pageCount < validationRules.pageNumbers.min) ||
			(validationRules.pageNumbers.max &&
				fileData.pageCount > validationRules.pageNumbers.max)
		) {
			validationMessage =
				validationRules.pageNumbers.min && !validationRules.pageNumbers.max
					? `Puoi caricare solamente file con minimo ${validationRules.pageNumbers.min} pagina/e. Si prega di verificare e riprovare`
					: validationRules.pageNumbers.max && !validationRules.pageNumbers.min
					? `Puoi caricare solamente file con massimo ${validationRules.pageNumbers.max} pagina/e. Si prega di verificare e riprovare`
					: `Puoi caricare solo file con un minimo di ${validationRules.pageNumbers.min} pagina/e e un massimo di ${validationRules.pageNumbers.max} pagina/e. Si prega di verificare e riprovare.`;
		}
	}

	if (
		validationRules.resolution &&
		fileData.resolution &&
		['.JPEG', '.PNG', '.JPG', '.PDF'].filter(fileType =>
			fileData.fileName.toUpperCase().endsWith(fileType),
		).length > 0 &&
		!validationMessage
	) {
		if (
			!(
				Object.values(fileData.resolution).filter(
					value =>
						validationRules.resolution?.toLowerCase() ===
						value.substring(0, value.indexOf(':')).toLowerCase(),
				).length === Object.keys(fileData.resolution).length
			)
		)
			validationMessage = `È possibile caricare il file solo con le stesse risoluzioni di ${validationRules.resolution}. Controlla la risoluzione del file e riprova.`;
	}

	if (validationMessage)
		showNotification({
			message: validationMessage,
			title: 'Convalida caricamento file.',
			type: 'danger',
		});

	return validationMessage.length === 0;
};

export const fileTypes = (
	file: UploadFileDetails,
	data: RigidUploadDocument[] | DynamicUploadDocument[],
) => {
	let fileType: string[] = [];

	if (Object.keys(file).length == 0 || !data || data.length <= 0)
		fileType = fileTypesForUpload
			.filter(g => FileMimeType[g.value as keyof typeof FileMimeType])
			.map(d => FileMimeType[d.value as keyof typeof FileMimeType]);
	const fileFormats = data.find(f => f.documentList.transcode === file.documentIdentifyingCode)
		?.documentList.formats;
	if (fileFormats && fileFormats.length > 0)
		fileType = fileFormats
			.filter(g => FileMimeType[g.value as keyof typeof FileMimeType])
			.map(d => FileMimeType[d.value as keyof typeof FileMimeType]);
	else
		fileType = fileTypesForUpload
			.filter(g => FileMimeType[g.value as keyof typeof FileMimeType])
			.map(d => FileMimeType[d.value as keyof typeof FileMimeType]);

	return fileType;
};

export const fileMinSize = (
	file: UploadFileDetails,
	data: RigidUploadDocument[] | DynamicUploadDocument[],
): number => {
	let fileSizeInMB = 0;
	const fileSizeInKB = data.find(f => f.documentList.transcode === file.documentIdentifyingCode)
		?.documentList.validationRules.documentSize.minSize;
	if (fileSizeInKB) {
		fileSizeInMB = Number(fileSizeInKB) / 1000;
	} else fileSizeInMB = 0;

	return fileSizeInMB;
};

export const fileMaxSize = (
	file: UploadFileDetails,
	data: RigidUploadDocument[] | DynamicUploadDocument[],
): number => {
	const fileSizeInKB = data.find(f => f.documentList.transcode === file.documentIdentifyingCode)
		?.documentList.validationRules.documentSize.maxSize;
	if (fileSizeInKB) {
		return Number(fileSizeInKB);
	} else return maxFileUploadInMB;
};

export const formValidations = (validationsRules: ValidationRules[], required: boolean) => {
	const newVal: RegisterOptions<FieldValues, string> = {
		required: {
			value: required,
			message: `Campi obbligatori`,
		},
		setValueAs: v => v.trim(),
	};
	validationsRules.forEach(v => {
		newVal[v.rule.value as keyof RegisterOptions<FieldValues, string>] = {
			value: v.value ? v.value : '',
			message: v.message,
		};
	});
	return newVal;
};

export const dataUrlToFile = async (
	dataUrl: string,
	fileName: string,
	type: string,
): Promise<File> => {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();
	return new File([blob], fileName, { type });
};

export const previewOfSignatureDoc = (d: FileDetail[], title: string) => {
	const f: IDocument[] = d
		.filter(fs => fs.fileBase64)
		.map(m => {
			const data: IDocument = {
				uri: window.URL.createObjectURL(
					createBlob(m.fileBase64 ?? '', getMimeType(m.fileType)),
				),
				fileData: m.fileBase64 || '',
				fileType: m.fileType,
			};
			return data;
		});
	{
		f.length > 0 &&
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<ConfirmDailog onClose={onClose} title={title}>
							<embed
								src={
									f[0].fileData
										? `data:application/pdf;base64,${f[0].fileData.toString()}`
										: ' '
								}
								width="100%"
								height="100%"
								// type="application/pdf"
							/>
						</ConfirmDailog>
					);
				},
			});
	}
};
export const responsviePreviewOfSignatureDoc = (d: FileDetail[], title: string) => {
	const f: IDocument[] = d
		.filter(fs => fs.fileBase64)
		.map(m => {
			const data: IDocument = {
				uri: window.URL.createObjectURL(
					createBlob(m.fileBase64 ?? '', getMimeType(m.fileType)),
				),
				fileData: m.fileBase64 || '',
				fileType: m.fileType,
			};
			return data;
		});
	{
		f.length > 0 &&
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<ConfirmdialogResponsive onClose={onClose} title={title}>
							<embed
								src={
									f[0].fileData
										? `data:application/pdf;base64,${f[0].fileData.toString()}`
										: ' '
								}
								width="100%"
								height="100%"
								// type="application/pdf"
							/>
						</ConfirmdialogResponsive>
					);
				},
			});
	}
};

export const convertDateMMDDYYtoDDMMYY = (originalDate: string) => {
	let date = originalDate;
	if (originalDate.includes('-')) {
		date = originalDate.split('-').join('/');
	}
	return date.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, '$2/$1/$3');
};

export function addSuffixInAttributesValue(
	value: string,
	attributes: Record<`data-${string}`, string> = {},
) {
	return Object.fromEntries(
		Object.keys(attributes).reduce((map, currentItem) => {
			return map.set(currentItem, attributes[currentItem as `data-${string}`] + value);
		}, new Map()),
	);
}

export const convertddmmyyyyToyyyymmdd = (originalDate: string) => {
	const newDate = originalDate.split('-').reverse().join('-');
	return formatDate(newDate);
};
export function getMimeType(fileExtension: string) {
	switch ('.' + fileExtension) {
		case '.png':
			return 'image/png';
		case '.jpg':
		case '.jpeg':
			return 'image/jpeg';
		case '.pdf':
			return 'application/pdf';
		case '.doc':
		case '.dot':
		case '.rtf':
			return 'application/msword';
		case '.docx':
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		case '.docm':
		case '.dotm':
			return 'application/vnd.ms-word.document.macroEnabled.12';
		case '.tif':
		case '.tiff':
			return 'image/tiff';
		case '.txt':
			return 'text/plain';
		case '.xls':
		case '.xlt':
			return 'application/vnd.ms-excel';
		case '.xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case '.xltx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.template';
		case '.xlsm':
			return 'application/vnd.ms-excel.sheet.macroEnabled.12';
		case '.xltm':
			return 'application/vnd.ms-excel.template.macroEnabled.12';
		default:
			return 'application/octet-stream';
	}
}

export const formatDate = (date: string) => {
	const arr = date.split('-');
	const y = arr[0];
	let m = arr[1];
	let d = arr[2];
	if (m.length == 1) {
		m = '0' + m;
	}
	if (d.length == 1) {
		d = '0' + d;
	}
	const newArr = [y, m, d];
	return newArr.join('-');
};

export const isMacOriOSAndSafari = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
	const isMacOS = /macintosh|mac os x/i.test(userAgent);
	const isiOS = /iphone|ipad|ipod/i.test(userAgent);

	return isSafari && (isMacOS || isiOS);
};

export const getName = (noteDetails: DocumentNoteHistory) => {
	const personName = noteDetails.personName.split(' ');
	const nameToShow =
		personName.length > 1
			? `${personName[personName.length - 1]} ${personName[0].split('')[0]}.`
			: `${personName[0]}`;
	return (
		<>
			<p>
				{noteDetails.note} - {nameToShow}
			</p>
		</>
	);
};

export const getHeightOfNotificationBox = () => {
	const ele = document?.querySelector('.notificationBox') as HTMLStyleElement;
	return ele?.offsetHeight;
};
export const getUploadIconsBasedOnState = (fileDetail: UploadFileDetails) => {
	return fileDetail?.docStatus.find(f => f.key === 'IsFileUploaded')?.value &&
		fileDetail?.docStatus.find(f => f.key === 'IsDocumentConfirm')?.value ? (
		<img className="m-1 w-4 h-4" src={OKIcon} title="Inviato" />
	) : fileDetail.docStatus.find(f => f.key === 'IsFileUploaded')?.value ? (
		<img
			className="m-1 w-4 h-4"
			src={InfoYellowIcon}
			title={fileDetail.fileDetail.map(m => `${m.fileName}.${m.fileType}`).toLocaleString()}
		/>
	) : (
		<img className="m-1 w-4 h-4" src={RedCrossIcon} title="Assente" />
	);
};

export const getUploadStatus = (fileDetail: UploadFileDetails) => {
	return fileDetail.docStatus.find(f => f.key === 'IsFileUploaded')?.value &&
		fileDetail.docStatus.find(f => f.key === 'IsDocumentConfirm')?.value ? (
		<p>Inviato</p>
	) : fileDetail.docStatus.find(f => f.key === 'IsFileUploaded')?.value ? (
		<p>Da inviare</p>
	) : (
		<p>Assente</p>
	);
};

export const formatDateTime = (date: Date) => {
	return convertDateMMDDYYtoDDMMYY(
		new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		}).format(date),
	);
};
